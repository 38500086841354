import * as React from 'react';
import {FC} from 'react';
// @ts-ignore
import * as styles from './login.module.less';
import {Link, navigate} from 'gatsby';
import {Card, Typography} from 'antd';
import {MainLayout} from '../Layout/MainLayout';
import CriblAuth0Icon from '../../static/svgs/cribl-logo.svg';
import {useFlags} from 'launchdarkly-react-client-sdk';

const {Title, Paragraph} = Typography;

export const LoginUnavailable: FC = () => {
  const {serviceAuth0Available} = useFlags();

  React.useEffect(() => {
    if (serviceAuth0Available === undefined) return;
    if (serviceAuth0Available) {
      void navigate('/');
    }
  }, [serviceAuth0Available]);

  return (
    <MainLayout className={'signup-content-auth0'} hideSideComponent={true}>
      <Card className={['main-card-auth0', styles.loginUnavailable].join(' ')}>
        <CriblAuth0Icon style={{height: 40, margin: '32px 0'}} />
        <Title>Login Unavailable</Title>
        <Paragraph>Please try again later.</Paragraph>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Paragraph>
          <Link to={'https://status.cribl.io'}>
            <a>Cribl.Cloud Status Page</a>
          </Link>
        </Paragraph>
      </Card>
    </MainLayout>
  );
};

export default LoginUnavailable;
